import Toast from 'src/components/common/Toast'
import { orderBy } from 'lodash'

export default function (raw) {
  let years
  let activeYear
  const bookLoaded = true
  const { currentChapters, deletedChapters } = raw

  if (!deletedChapters.length && !currentChapters.length) {
    Toast({
      message: '本书暂无内容'
    })
  }
  try {
    currentChapters.map(item => {
      item.deleted = false
    })
    deletedChapters.map(item => {
      item.deleted = true
    })

    let chapters = currentChapters
    chapters = orderBy(chapters, ['month'], ['asc']) // 按照章节月份正序

    raw.chapters = chapters

    const timeLine = {} // 时间线数据源
    let pages = 3 // Turn 起始页数为 2
    chapters.map(item => {
      const year = item.month.substr(0, 4)
      const month = item.month.substr(4, 2)
      timeLine[year] = timeLine[year] || []
      timeLine[year].push(Object.assign(item, { month, time: item.month, pages }))
      // 书籍月份插页对应页数
      pages += item.totalPage + 1
    })
    raw.timeLine = timeLine
    years = Object.keys(timeLine).reverse()
    activeYear = years[0]
  } catch (err) {
    console.log(err)
  }
  const book = raw
  return { years, activeYear, book, bookLoaded }
}
